import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/storage';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Main Menu';
  public backStack: Array<any> = [];
  public titleStack = [];
  public cards: Observable<any[]>;
  public subtitleDict = {};
  public sections = {
    'description': null,
    'audio': null,
    'timer': null,
    'unorderedLists': null,
    'orderedLists': null,
    'phone': null
  };
  public timerInterval;

  constructor(private firestoreDB: AngularFirestore, private storage: AngularFireStorage) {
    // Retrieve snapshot from Firebase so that cards can be populated with correct information. Snapshot is required as opposed to
    // value change due to the fact that you do not have access to the names of the documents in the value change method.
    // See if the card has a subtitle associated with the card dropdown.
    this.cards = firestoreDB.collection('Home').snapshotChanges();
    firestoreDB.collection('Home').snapshotChanges().subscribe( value => {
      for (let i = 0; i < value.length; i++) {
        firestoreDB.doc(value[i].payload.doc.ref.path).valueChanges().subscribe( v => {
          this.subtitleDict[value[i].payload.doc.ref.id] = v['subtitle'];
        });
      }
    });
  }

  ngOnInit() {}

  /**
   * When a card is clicked it needs to retrieve the next page's cards from Firebase as well as store the previous page's card data.
   * The stored card data will be used for navigation and title of the page. Check each card value to see if it has a one of the
   * valid subsections, if there is a valid subsection or audio file, then set subsection to the value stored in the card.
   * Setting the subsection will reveal a div tag in app.component.html and hide the normal card view.
   * @param card
   */
  cardClicked(card: any) {
    this.firestoreDB.collection(card.payload.doc.ref.path + '/cells').snapshotChanges().subscribe(value => {
      if (this.backStack[this.backStack.length - 1] !== this.cards) {
        this.backStack.push(this.cards);
        this.titleStack.push(this.title);
        this.title = card.payload.doc.id;
      }
      if (value.length > 0) {
        this.cards = this.firestoreDB.collection(card.payload.doc.ref.path + '/cells').snapshotChanges();
        console.log(value);
        for (let i = 0; i < value.length; i++) {
          this.firestoreDB.doc(value[i].payload.doc.ref.path).valueChanges().subscribe( v => {
            if (v['subtitle']) {
              console.log(v['subtitle']);
              this.subtitleDict[value[i].payload.doc.ref.id] = v['subtitle'];
            } else {
              this.subtitleDict[value[i].payload.doc.ref.id] = '';
            }
            console.log(this.subtitleDict);
          });
        }
      } else {
        this.firestoreDB.doc(card.payload.doc.ref.path).valueChanges().subscribe( val => {
          if (val['description']) {
            Object.keys(this.sections).forEach(section => {
              Object.keys(val).forEach(key => {
                if (key === section && section !== 'unorderedLists' && section !== 'orderedLists') {
                  if (section === 'audio') {
                    const storageRef = this.storage.ref(val['audio']);
                    storageRef.getDownloadURL().subscribe(downloadURL => {
                      this.sections['audio'] = downloadURL;
                    }, error => {
                      console.log(error);
                    });
                  } else {
                    this.sections[section] = val[key];
                  }
                } else if ((key === section)) {
                  this.sections[section] = (val[key]);
                }
              });
            });
          }
        }, error => {
          console.log(error);
        });
      }
    }, error => {
      console.log(error);
    });
  }

  /**
   * Used to control the timer. The two valid operations that it checks for are 'start' and 'restart'.
   * 'start': initializes the timer with an interval and begins incrementing time and the progress bar
   * 'restart': resets the time, clears the progress bar, and clears the interval
   * @param operation
   */
  timerControls(operation) {
    if (operation === 'start') {
      let interval;
      clearInterval(this.timerInterval);
      let time = this.sections['timer'];
      const progressBarInterval = 100 / this.sections['timer'];
      let progressBar = progressBarInterval;
      interval = setInterval(function () {
        if (time > 0) {
          time--;
          document.getElementById('time').innerHTML = time.toString();
          document.getElementById('progress').style.width = progressBar + '%';
          progressBar = progressBar + progressBarInterval;
        } else {
          clearInterval(interval);
        }
      }, 1000);
      this.timerInterval = interval;
    } else if (operation === 'restart' && this.timerInterval) {
      document.getElementById('time').innerHTML = this.sections['timer'].toString();
      document.getElementById('progress').style.width = '0%';
      clearInterval(this.timerInterval);
    }
  }

  /**
   *  Navigating back will pop off the top of the title stack and the top of the card stack this allows the back button to
   *  give the appearance of navigating back when in actuality we are just changing the information on the static page.
   */
  navigateBack() {
    if (this.titleStack[this.titleStack.length - 1] && this.backStack[this.backStack.length - 1]) {
      this.title = this.titleStack.pop();
      this.cards = this.backStack.pop();
      Object.keys(this.sections).forEach( key => {
        if (this.sections[key]) {
          this.sections[key] = null;
        }
      });
    }
  }

  /**
   * Checks to see if the given card name has an image associated with the card
   * @param cardName
   */
  hasCardImage(cardName): boolean {
    const cardImages = ['cbt', 'f.a.q.', 'grounding',
      'guided exercises', 'i\'m in a crisis', 'learn',
      'local resources', 'mindfulness', 'physical health',
      'self care', 'support', 'tools', 'nutrition', 'self-care'];
    let hasImage = false;
    if (cardImages.indexOf(cardName.toLowerCase()) > -1) {
      hasImage = true;
    }
    return hasImage;

  }

  /**
   * If a card has an image then this method is used to retrieve the image name from a dictionary and that image name is used for the src
   * of an img tag.
   * @param imageName
   */
  getCardImage(imageName): string {
    const imageReferences = {
      'cbt': '../assets/card-images/CBT.png',
      'f.a.q.': '../assets/card-images/FAQ.png',
      'grounding': '../assets/card-images/Grounding.png',
      'guided exercises': '../assets/card-images/Guided%20Exercises.png',
      'i\'m in a crisis': '../assets/card-images/In%20a%20crisis.png',
      'learn': '../assets/card-images/Learn.png',
      'local resources': '../assets/card-images/Local%20Resources.png',
      'mindfulness': '../assets/card-images/Mindfulness.png',
      'physical health': '../assets/card-images/Physical%20Health.png',
      'self-care': '../assets/card-images/Self-Care.png',
      'support': '../assets/card-images/Support.png',
      'tools': '../assets/card-images/Tools.png',
      'nutrition': '../assets/card-images/Nutrition.png'
    };
    return imageReferences[imageName];
  }
}
